.footer {
    display: flex;
    justify-content: center;
    padding: 0.25rem 0;
    /* background-color: #f37121; */
    background-color: rgba(16, 17, 20, 1);
    border-top: 1px solid #fff;
    color: #fff;
}

.footer small {
    color: #fff;
}

.footer a {
    color: #fff;
}