.supported-brands {
    padding: 2rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.supported-brands .title {
    margin-bottom: 2rem;
    font-weight: bold;
}

.brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-direction: column;
    color: #2b2828;
}

.brand-logo img {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
}

.supported-brands-title {
    color: #003e70;
    width: 100%;
    text-align: center;
    font-size: 42px;
    line-height: 1.25;
    font-weight: 600;
    margin-bottom: 8px;
}

/* .supported-brands-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
} */