:root {
    background-color: white;
    --primary: white !important;
    --primary-hover: transparent !important
        /* Set your desired primary color here */
}



.vehicle-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(246, 246, 247);

}

.vehicle-details-main-content {
    display: flex;
    width: 70%;
    background-color: white;
    margin: auto;
    flex-direction: column;
}

.vehicle-details-info-section {
    padding: 0.5rem;
}

.vehicle-details-header {
    padding: 1rem;
    display: flex;
    flex-direction: row;
}



.vehicle-details-title {
    font-size: 1.5rem;
    font-weight: 600;
    font: 400;
    color: black;
}

.vehicle-details-model {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 1rem;
}

.vehicle-details-price {
    padding-top: 1rem;
    font-size: 1.75rem;
    color: rgb(14, 14, 14);
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 1rem;
}



.vehicle-details-image-section {
    position: relative;
    max-width: 600px;
    width: 100%;
    /* border: 1px solid rgb(146, 129, 242); */
    /* box-shadow: #2b15453d 1px 10px 10px 5px; */
}

.image-gallery .image-gallery-slide-wrapper .image-gallery-left-nav,
.image-gallery .image-gallery-slide-wrapper .image-gallery-right-nav {
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    height: 0px;
    /* border-radius: 50%; */
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    font-size: 24px;
    /* z-index: 1000; */
}

.image-gallery .image-gallery-slide-wrapper .image-gallery-left-nav {
    left: 6px;
    padding: 0px;
    margin: 0px;
    border-color: transparent;
    border: 0;
    width: 0;
    height: 0;
}

.image-gallery .image-gallery-slide-wrapper .image-gallery-right-nav {
    right: 56px;
    padding: 0px;
    margin: 0px;
    width: 0;
    height: 0;
}

.image-gallery-icon {
    display: relative;
}

.image-gallery-swipe {
    background-color: white;
}

.image-gallery-svg {
    display: none;
}

/* Hide the element by default */
.vehicle-details-mobile-only {
    display: none;
}

.vehicle-details-desktop-only {
    display: block;
}

.vehicle-details-basic-details {
    border-bottom: 1px solid #dcdcdc;
    margin: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;

}

.vehicle-details-basic-details-title {
    font-weight: 600;
    color: black;
    margin-bottom: 1rem;
}

.vehicle-details-basic-details-title svg {
    width: 24px;
    height: 24px;
}

.vehicle-details-basic-details-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 0 2rem;
}

.vehicle-details-basic-details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.vehicle-details-basic-details-row p {
    font-weight: 600;
    color: black;
    padding: 0 1rem;
}

.vehicle-details-basic-details-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 0 2rem
}

.vehicle-details-nav {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1rem auto;
    color: #1166a8;
}

.vehicle-details-nav svg {
    height: 28px;
    width: 24px;
    fill: #1166a8;
}


@media (max-width: 768px) {
    .vehicle-details-image-section {
        margin: auto;
    }

    .vehicle-details-main-content {
        display: flex;
        width: auto;
        margin: auto;
        /* max-width: 300px; */
        background-color: white;
        /* margin: 0; */
        flex-direction: column;
    }

    .vehicle-details-header {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
    }

    .vehicle-details-image-section {
        position: relative;
        max-width: 300px;
        width: 100%;
        /* border: 1px solid rgb(146, 129, 242); */
        /* box-shadow: #2b15453d 1px 10px 10px 5px; */
    }

    .vehicle-details-mobile-only {
        display: block;
    }

    .vehicle-details-desktop-only {
        display: none;
    }

    .vehicle-details-basic-details {
        border-bottom: 1px solid #dcdcdc;
        margin: 2rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;

    }

    .vehicle-details-basic-details-title {
        font-weight: 600;
        color: black;
        margin-bottom: 1rem;
    }

    .vehicle-details-basic-details-title svg {
        width: 24px;
        height: 24px;
    }

    .vehicle-details-basic-details-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0rem
    }

    .vehicle-details-basic-details-row {
        display: flex;
        flex-direction: row;

    }

    .vehicle-details-basic-details-row p {
        font-weight: 600;
        color: black;
        padding: 0rem;
    }

    .vehicle-details-nav {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem auto;
        color: #1166a8;
    }

    .vehicle-details-nav svg {
        height: 24px;
        width: 24px;
        fill: #1166a8;
    }

}




.vehicle-details-car-details {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgb(164, 164, 164);
    width: 90%;
    margin: auto;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    gap: 40px;
}

.vehicle-details-detail-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(33.33% - 20px);
    padding: 10px;
    background-color: white;
}

.vehicle-details-icon svg {
    width: 24px;
    height: 24px;
    fill: black;

}

.vehicle-details-text {
    display: flex;
    flex-direction: column;
}

.vehicle-details-label {
    font-weight: 600;
    color: #898989;
    font-size: large;
}

.vehicle-details-value {
    font-size: 14px;
    color: #333;
}