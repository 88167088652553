.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    flex: 1;
}

.contact-container {
    max-width: 800px;
    width: 100%;
    background: #fff;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-form-title {
    color: #003e70;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 38px;
    line-height: 1.25;
    font-weight: 600;
}

.contact-form-subTitle {
    color: #003e70;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 8px 0 50px;
    text-align: center;
    font-size: 46px;
    line-height: 1.25;
    font-weight: 600;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-button {
    padding: 0.75rem;
    background-color: #003e70;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}


@media (max-width: 768px) {
    .contact-container {
        padding: 1rem;
    }

    .contact-button {
        font-size: 0.9rem;
    }
}

/* ContactForm.css */

.contact-section {
    padding: 2em;
    background-color: #f9f9f9;
}

.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2em;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form .form-group {
    margin-bottom: 1em;
}

.contact-form label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contact-form .contact-button {
    display: inline-block;
    padding: 0.75em 1.5em;
    background-color: #003E77;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message-box {
    margin-top: 1em;
    padding: 1em;
    border-radius: 4px;
}

.message-box.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message-box.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/* ContactForm.css */

.contact-section {
    padding: 2em;
    background-color: #f9f9f9;
}

.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2em;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form .form-group {
    margin-bottom: 1em;
}

.contact-form label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contact-form .contact-button {
    display: inline-block;
    padding: 0.75em 1.5em;
    background-color: #003E77;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 0.5em;
    display: block;
}

.message-box {
    margin-top: 1em;
    padding: 1em;
    border-radius: 4px;
}

.message-box.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.message-box.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}