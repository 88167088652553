.hero-section {
    position: relative;
    background: url('/public/assets/heroImage_compressed.png') no-repeat center center/cover;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    margin-bottom: 0;
}

.hero-content {
    padding: 1rem;
}

.hero-content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffc107;
    /* Yellow color for the headline */
}

.hero-content p {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* Adding a shadow for better readability */
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.hero-button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 1.8rem;
    }

    .hero-content p {
        font-size: 1rem;
    }

    .hero-button {
        padding: 0.5rem 1rem;
    }
}