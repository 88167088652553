.listing-not-found-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(246, 246, 247);
}

.listing-not-found-new-search {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 1rem auto;
    color: #1166a8;
    cursor: pointer;
    justify-content: center;
}