body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  margin-top: 4rem;
  padding: 1rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* LTR-specific styles */
.ltr {
  direction: ltr;
}

/* RTL-specific styles */
.rtl {
  direction: rtl;
}

.contact-brands-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 2rem;
}

@media (max-width: 768px) {
  .contact-brands-wrapper {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
}

html {
  scroll-behavior: smooth;
}