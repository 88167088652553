.faq-container {
    background-color: #f9f9f9;
}

.faq-section {
    display: flex;
    width: 70%;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    padding: 2rem;
    text-align: left;
}

.title {
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    color: #0d4f78;
    font-weight: 800;
    font-size: 38px;
    line-height: 1.35;
    margin: auto;
    width: 70%;
}

.faq-accordion {
    background-color: #f9f9f9;
    border: none;
    box-shadow: none;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
}

.faq-accordion-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    background-color: #f9f9f9;
}

.faq-accordion-details {
    background-color: #f9f9f9;
    padding: 1rem;
}

.question {
    font-weight: bold;
    font-size: 1rem;
    color: #000;
}

.answer {
    font-size: 0.9rem;
    color: #000;
    margin-bottom: 0;
}

.dropdown-icon {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.dropdown-icon.open {
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
        margin: auto;
        width: 90%;
    }

    .faq-section {
        display: flex;
        width: 90%;
        flex-direction: column;
        margin: auto;
        justify-content: center;
        padding: 2rem;
        text-align: left;
    }

    .question {
        font-size: 1rem;
    }

    .answer {
        font-size: 0.9rem;
    }
}