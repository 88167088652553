.car-search-section {
    padding: 2rem;
    /* background-color: #f5f5f5; */
    background-color: #fff;
    text-align: left;
    max-width: 800px;
    margin: auto;
    margin-bottom: 1rem;
}

.car-search-component-title {
    color: #003e70;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 46px;
    line-height: 1.25;
    font-weight: 600;
}

.car-search-component-subTitle {
    text-align: center;
    margin: 0;
}

.car-search-form-question {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    color: #333;
}

.car-search-form {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
}

.search-button {
    margin-top: 1rem;
    background-color: #ff9800;
    color: white;
}

.mobile-search-button {
    background: #ff751a;
    margin-top: 1rem;

}

.search-button:hover {
    background-color: #e68900;
}

@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
    }

    .car-search-form {
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
}


.buttons-section {
    margin-top: 1rem;
}