/* Common styles */
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px grey;
    width: 100%;
    background-color: #f37121;
    /* background-color: #003e70; */
    color: #fff;
    /* padding: 0.5rem 24px; */

}

.navbar-logo {
    background-color: #fff;
    padding: 0.35rem 2rem 0.6rem 1rem;
}

.triangle-up {
    width: 0;
    height: 0;
    margin-left: -20px;
    border-bottom: 63px solid #f37121;
    border-left: 20px solid transparent;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0rem;
}

.navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0px 4px;

}

.navbar-brand img {
    width: 200px;
    height: auto;

}

.navbar-menu {
    display: flex;
    align-items: center;
}

.navbar-nav {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar-nav li {
    display: inline;
    padding-top: 8px;
    padding-bottom: 8px;
}

.navbar-nav a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}

.navbar-nav a:hover {
    text-decoration: underline;
}

.language-switcher {
    position: relative;
}

.menuButton {
    display: none;
}

/* LTR-specific styles */
html[dir="ltr"] .navbar {
    direction: ltr;
}

html[dir="ltr"] .navbar-nav {
    margin-left: auto;
}

html[dir="ltr"] .dropdown-menu {
    right: 0;
}

/* RTL-specific styles */
html[dir="rtl"] .navbar {
    direction: rtl;
}

html[dir="rtl"] .navbar-nav {
    margin-right: auto;
}

html[dir="rtl"] .dropdown-menu {
    left: 0;
}

@media (max-width: 768px) {
    .navbar-nav {
        display: none;
    }

    .navbar-brand img {
        width: 200px;
        height: auto;
        padding-top: 8px;
    }

    .navbar {
        display: flex;
        justify-content: center;
        align-items: normal;
        box-shadow: 0 2px 4px grey;
        width: 100%;
        background-color: #f37121;
        /* background-color: #003e70; */
        color: #fff;
        /* padding: 0.5rem 24px;
    }

    .menuButton {
        display: inline-flex;
    }

    .navbar-brand {
        /* font-weight: bold;
        font-size: 1.5rem;
        color: #fff;
        margin: 10px; */

    }
}