.productDescriptionContainer {
    color: #003e70;
    background-color: white;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 8px 0 50px;
    text-align: center;
    font-size: 46px;
    line-height: 1.25;
    font-weight: 600;
}

.productDescriptionSubContainer {
    color: black;
    font-size: 12px;

}