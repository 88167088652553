.autoscout24-search-results {
    background-color: #f1f1f1;
    padding: 20px;
}

.search-result-page-title,
.similar-cars-title {
    font-weight: 500;
    text-align: left;
    /* color: #003e70; */
    color: #333;
    font-size: 28px;
    /* line-height: 1.35; */
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 1rem;
    padding-right: 2rem;
}

.search-item-details,
.car-listing .p-4 {
    padding: 0.5rem;
    background-color: #fff;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 2rem;
    background-color: white;
}

.error-text {
    color: red;
    text-align: center;
}

.no-results-text {
    text-align: center;
    color: gray;
}

.car-listings {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.car-listing {
    display: flex;
    flex-direction: column;
    border: 1px solid #dde2e5;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    max-width: 330px;
    height: 450px;
    /* max-height: 400px;
    overflow: hidden; */
    cursor: pointer;
}

.car-title {
    font-size: 16px;
    color: #0d4f78;
    margin-bottom: 0;
}

.car-subTitle {
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    /* Adjust the line-height to your preference */
    min-height: 2.4em;
    /* This ensures that the subtitle always takes up the space of 2 lines */
}

.car-listing img {
    width: 100%;
    min-height: 222px;
    height: auto;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid grey;
}

.car-details-section {
    border-top: 1px solid #c1c0c0;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 15px;
    color: #433f3f;
    font-weight: 600;
    line-height: 1;
}

.car-price-section {
    font-size: 16px;
    color: #0d4f78;
}

.car-price-section-value {
    margin: 0;
}

.car-listing .text-blue-500 {
    color: #003e70;
}

.car-listing .bg-orange-500 {
    background-color: #003e70;
    border-color: #003e70;
}

.car-listing .text-white {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
}

.pagination-bar {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.car-listing-desktop {
    display: flex;
    flex-direction: row;
    /* Place the image and details side by side */
    align-items: center;
    width: 100%;
    /* Stretch to fill the screen horizontally */
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer;
    background-color: #fff;
}

.car-image-desktop {
    width: 300px;
    /* Set a fixed width for the image */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.car-details-desktop {
    flex: 1;
    /* Take up all available space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    /* Add spacing between image and details */
}

.car-img-desktop {
    max-width: 100%;
    height: auto;
}

.car-icon-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
}

.car-title-desktop {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.car-subTitle-desktop {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.car-price-section-desktop {
    font-size: 1.5rem;
    color: #f37121;
    margin-bottom: 0.5rem;
}

.car-info-desktop {
    font-size: 0.9rem;
    color: #333;
}

.SortDropdown_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.SortDropdown_label {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-right: 8px;
}

.SortDropdown_title {
    font-weight: bold;
}

.SortDropdown_link {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
}

.SortDropdown_link:hover {
    text-decoration: underline;
}

.SortDropdown_options {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    width: max-content;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    list-style: none;
    /* Remove bullets from the ul */
    padding: 0;
    margin: 0;
}

.SortDropdown_options li {
    padding: 8px 12px;
    cursor: pointer;
    list-style-type: none;
    /* Ensures no bullets on li elements */
}

.SortDropdown_options li:hover {
    background-color: #f5f5f5;
}

@media (max-width: 600px) {
    .car-listing {
        width: 100%;
        height: 420px;
    }

    .car-listings {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }

    .autoscout24-search-results {
        padding: 10px;
    }

    .search-result-page-title,
    .similar-cars-title {
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;
        /* color: #003e70; */
        color: #333;
        font-size: 21px;
        /* line-height: 1.35; */
        /* text-wrap: wrap; */
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}