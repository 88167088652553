.process-section {
    padding: 2rem;
    background-color: #fff;
    text-align: center;
}

.process-section-title {
    color: #003e70;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 70px 0 20px;
    text-align: center;
    font-size: 38px;
    line-height: 1.25;
    font-weight: 600;
}

.process-step {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.feature-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px 290px;
}

.feature-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* transition: transform 0.3s ease; */
    display: flex;
    flex-direction: row;
}

.icon {
    font-size: 2rem;
    color: #19476d;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-card-title {
    font-weight: 700;
    line-height: 1.25;
    font-size: 24px;
}

.feature-card-description-container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.feature-logo {
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
    margin-top: 36px;
}




@media (max-width < 2000px) and (min-width > 768px) {

    .feature-cards-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
        padding: 20px 20px;
    }
}

@media (max-width: 768px) {

    .feature-cards-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
        padding: 20px 20px;
    }
}